import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@sweetalert2/theme-dark/dark.css";
import "./i18n";
import { UserAuthContextProvider } from "./Components/Authentications/Others/AuthContext";
import ScrollToTop from "./Assets/ScrollTop";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js", {
      scope: "/",
    })
    .then((registration) => {
      console.log(
        "Index: Service Worker registered successfully:",
        registration
      );
    })
    .catch((error) => {
      console.error("Index: Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserAuthContextProvider>

    <App />
    </UserAuthContextProvider>
  </React.StrictMode>
);
