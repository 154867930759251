import axios from "axios";
import Swal from "sweetalert2";
import commonHttpService from "./commonHttpService";

export async function GlobalLogout() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/auth/logout`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function GetLogo() {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/getLogos?x-auth-user-type=manager`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function PostLogo(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/user/uploadLogo`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetBoards() {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/getBoards`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetBanner() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/notification/getBanners`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetNotifications(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/notification/getNotifications`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function UpdateProfile(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/user/admin/editProfile`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetTeams(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/team/getMyTeam`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function JoinedLeagues(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getJoinedLeagues`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetLeagues(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getLeagues`,
      formData
    );
    if (!data.error) {
    } else {
      // Swal.fire({
      //   toast: true,
      //   icon: "error",
      //   position: "top-end",
      //   title: data?.message,
      //   showConfirmButton: false,
      //   timerProgressBar: true,
      //   timer: 3000,
      // });
      console.log(data?.message);
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetLeaguesById(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/viewLeague/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function JoinLeagues(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/joinLeagueManager`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function ChangeProfileStatus(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/team/changeProfileStatus`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetUserProfile(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/user/getUserProfile/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function DeleteStudent(id) {
  try {
    const { data } = await commonHttpService.delete(
      `${process.env.REACT_APP_API_URL}/team/deleteStudent/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function ConfirmationProfile(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/team/acceptRejectStudent`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetHonorCode() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/auth/getHonorCode`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetStudentRequest(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/team/getStudentRequests`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetJoinedMembers(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getJoinedTournaments`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetIndividualTournaments(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getTournaments`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function GetClubLeaderBoard(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/player/getLeaderboard`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetHistoricalClubLeaderBoard(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/player/getHistoricalLeaderboard`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getPastLeagueChampion(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/player/getPastLeagueChampion`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetTeamLeague(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getLeagues`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetLeagueClubLeaderBoard(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/player/getLeaderboard`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetMyProfile() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/user/getMyProfile`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetSponsors() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/getSponsorList`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function GetMatches(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getMatches`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getResults(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getResults`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function RegisterTeamManager(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/user/signup`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function AddTournament(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/tournament/addTournament`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function Editournament(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/tournament/editTournament`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function ViewTournament(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/viewTournament/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function changeTournamentStatus(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/changeTournamentStatus/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function deleteTournament(id) {
  try {
    const { data } = await commonHttpService.delete(
      `${process.env.REACT_APP_API_URL}/tournament/deleteTournament/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetPrizePool(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getTournamentPrizePool`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetPrizePoolById(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/viewTournament/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetPrizePoolLeague(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/getLeaguePrizePool`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function GetPrizePoolLeagueById(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/viewLeague/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function JoinTournament(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/tournament/joinTournament`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function Login_TeamManager(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function Verify_OTP(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/auth/verifyOTP`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function Verify() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/auth/acceptHonorCode`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function Resend_OTP(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/auth/forgotPassword`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function ResetPassword(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/auth/updatePassword`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function HelpSupport(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/account/getTickets`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function PostFeedback(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/account/raiseTicket`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function RejectLeagues(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/tournament/rejectLeague/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      console.log(data?.message);
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function removeProfilePicture() {
  try {
    const { data } = await commonHttpService.delete(
      `${process.env.REACT_APP_API_URL}/user/removeProfilePicture`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function changeAlumniStatus(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/user/changeAlumniStatus`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function changePassword(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/auth/changePassword`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function sendPushNotification(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/notification/sendPushNotification`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getMyCertificates(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/account/getMyCertificates`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getAttendance(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/coaching/getAttendance`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getCertificateById(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/account/getCertificateById/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getCoaches(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/coaching/getCoaches`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function bookSession(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/coaching/bookSession`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function confirmSession(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/coaching/confirmSession`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getMySessions(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/coaching/getMySessions`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function viewSession(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/coaching/viewSession/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function addBackup(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/team/addBackup`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getBackups() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/team/getBackups`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getManagerModules() {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/team/getManagerModules`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function TournamentPayment(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/tournament/sponsorTournament`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function updateBackup(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/team/updateBackup`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function deleteBackup(id) {
  try {
    const { data } = await commonHttpService.delete(
      `${process.env.REACT_APP_API_URL}/team/deleteBackup/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function uploadMedia(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/chat/uploadMedia`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function markAttendance(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/coaching/markAttendance`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function startRecording(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/coaching/startRecording`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function stoptRecording(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/coaching/stopRecording`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function exitSession(formData) {
  try {
    const { data } = await commonHttpService.put(
      `${process.env.REACT_APP_API_URL}/coaching/exitSession`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function renewTournament(formData) {
  try {
    const { data } = await commonHttpService.post(
      `${process.env.REACT_APP_API_URL}/tournament/renewTournament`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function getBannerById(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/notification/getBannerById/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function readNotification(id) {
  try {
    const { data } = await commonHttpService.get(
      `${process.env.REACT_APP_API_URL}/notification/readNotification/${id}`
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function manageAlumni(formData) {
  try {
    const { data } = await commonHttpService.patch(
      `${process.env.REACT_APP_API_URL}/user/manageAlumni`,
      formData
    );
    if (!data.error) {
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: data?.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    }

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
