import React from "react";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next";
import Swal from "sweetalert2";

function MainContactUs() {
  const location = useLocation();
  return (
    <>
      <div>
        <header className="header_main coach_header bg-transparent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-8">
                <Link className="logo_web" to="/">
                  <img src="./assets/img/comman/logo.png" alt="" />
                </Link>
              </div>
              <div className="col-lg-4 d-lg-block d-none">
                <div className="header_menus text-center ps-5">
                  <Link
                    className={location.pathname === "/" ? "active" : ""}
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    className={location.pathname === "/AboutUs" ? "active" : ""}
                    to="/AboutUs"
                  >
                    About Us
                  </Link>
                  <Link
                    className={
                      location.pathname === "ContactUs" ? "active" : ""
                    }
                    to="/ContactUs"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-8 mt-md-0 mt-2 d-lg-block d-none">
                <div className="row justify-content-end">
                  <div className="col-auto">
                    <Link to="/welcome" className="btn_1 text-white px-4">
                      Login
                    </Link>
                  </div>
                  <div className="col-auto">
                    <Link to="/welcome" className="btn_2 text-white px-4">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-4 d-lg-none d-block text-end">
                <button
                  className="btn_1 border-0 w-fit px-3"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <i className="bi bi-list text-white fs-1" />
                </button>
                <div
                  className="offcanvas offcanvas-end mobile_header"
                  tabIndex={-1}
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <div className="col-3">
                      <Link className="logo_web" to="/">
                        <img src="./assets/img/comman/logo.png" alt="" />
                      </Link>
                    </div>
                    <button
                      type="button"
                      className="btn-close text-reset border-0 w-fit px-3 bg-white"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="header_menus text-start"></div>
                    <div className="row">
                      <div className="col-12 text-start mb-4">
                        <Link to="/welcome" className="btn_1 text-white w-100">
                          Login
                        </Link>
                      </div>
                      <div className="col-12 text-start mb-4">
                        <Link to="/welcome" className="btn_2 text-white w-100">
                          Register
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="about_us landing_about comman_padding comman_bg_img2">
          <div className="container">
            <div className="studentbanner_text mt-5 text-center">
              <h1 className>Contact us:</h1>
            </div>
            <div className="content_outer p-md-5 p-3 rounded-3">
              <form className="form_design row" action="#">
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="#">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    fdprocessedid="d43ts"
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="#">Last Number</label>
                  <input
                    type="text"
                    className="form-control"
                    fdprocessedid="sv67fk"
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="#">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    fdprocessedid="9yqli8"
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="#">Mobile No.</label>
                  <input
                    type="text"
                    className="form-control"
                    fdprocessedid="eq3o1"
                  />
                </div>
                <div className="form-group mb-4 col-md-6 mt-5">
                  <div className="st_support">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row mb-4">
                          <div className="col-auto">
                            <div className="st_support_icon">
                              <img
                                src="./assets/img/comman/Email__st.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col ps-0">
                            <div className="st_support_details">
                              <span>Email:</span>
                              <a href="mailto:contact@mindleague.com">
                                contact@mindleague.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-auto">
                            <div className="st_support_icon">
                              <img
                                src="./assets/img/comman/Phone_st.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col ps-0">
                            <div className="st_support_details">
                              <span>Phone:</span>
                              <a href="tel:(345) 678 - 2345">
                                (345) 678 - 2345
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <div className="row">
                    <div className="col-md-4 col-7 ms-auto">
                      <a
                        className="comman_btn1"
                        href="javascript:;"
                        onClick={() =>
                          Swal.fire({
                            toast: true,
                            icon: "success",
                            position: "top-end",
                            title: "Submitted",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                          })
                        }
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <footer className="footer_main">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer_about">
                  <Link to="/TeamManager/Home">
                    <img
                      className="footer_logo"
                      src="/assets/img/comman/Mindleague_white.svg"
                      alt=""
                    />
                  </Link>
                  <p>
                    {t(
                      "Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam mauris sed ma"
                    )}
                  </p>
                  <div className="footer_social">
                    <a href="javascript:;">
                      <img src="/assets/img/comman/Facebook.svg" alt />
                    </a>
                    <a href="javascript:;">
                      <img src="/assets/img/comman/Twitter.svg" alt />
                    </a>
                    <a href="javascript:;">
                      <img src="/assets/img/comman/Instagram.svg" alt />
                    </a>
                    <a href="javascript:;">
                      <img src="/assets/img/comman/LinkedIn.svg" alt />
                    </a>
                    <a href="javascript:;">
                      <img src="/assets/img/comman/YouTube.svg" alt />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer_box">
                      <h2>{t("Team Manager")}</h2>
                      <div className="footermenus">
                        <Link to="/TeamManager">◉ Team Manger</Link>
                        <Link to="/Student">◉ Student</Link>
                        <Link to="/Coach">◉ Coach</Link>
                        <Link to="/Alumni">◉ Alumni</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="footer_box">
                      <h2>{t("Customer Services")}</h2>
                      <div className="footermenus">
                        <Link to="/AboutUs">◉ {t("About Us")}</Link>
                        <Link to="/Terms">◉ {t("Terms & Conditions")}</Link>
                        <Link to="/Privacy">◉ {t("Privacy Policy")}</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="footer_support">
                      <div className="row mb-4 pb-3">
                        <div className="col-auto">
                          <div className="support_icom">
                            <img src="/assets/img/comman/Email.svg" alt />
                          </div>
                        </div>
                        <div className="col">
                          <div className="support_details">
                            <span>{t("Email")}</span>
                            <a href="mailto:contact@mindleague.com">
                              contact@mindleague.com
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <div className="support_icom">
                            <img src="/assets/img/comman/Phone.svg" alt />
                          </div>
                        </div>
                        <div className="col">
                          <div className="support_details">
                            <span>{t("Phone Number")}</span>
                            <a href="tel:(345) 678 - 2345">(345) 678 - 2345</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_container">
            <p>
              Copyright © 2025 BRIX Agency | All Rights Reserved |
              mindleague.com
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default MainContactUs;
